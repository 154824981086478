import Vimeo from "@vimeo/player";
import { Carousel } from "antd";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import Image from "@components/shared/sub/image";
import { Maybe, SanityImageWithMeta, SanityVideo } from "@graphql-types";
import {
  IMAC_BREAKPOINT,
  MAX_WIDTH,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  colors,
  icons,
} from "@util/constants";
import { Container, P } from "@util/standard";
import { CarouselProps, CarouselRef } from "antd/lib/carousel";
import { Helmet } from "react-helmet";
interface Props {
  title: Maybe<string> | undefined;
  description: Maybe<string> | undefined;
  videoUrl: Maybe<string> | undefined;
  thumbnail?: Maybe<SanityImageWithMeta> | undefined;
  isVideoCarousel?: boolean;
  video?: Maybe<SanityVideo> | undefined;
  hasThumbnail?: boolean;
  customMargin?: string;
  customMobileMargin?: string;
}
const Title = styled.h2`
  text-align: center;
  color: black;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
    margin: 0 auto;
  }
`;
const Description = styled(P)`
  text-align: center;
  margin: 10px auto 65px auto;
  width: 70%;
  color: black;
  max-width: 900px;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-bottom: 35px;
    width: 78%;
  }
`;

const ImageWrapper = styled.div<{
  isVideoCarousel?: boolean;
  hasThumbnail?: boolean;
}>`
  width: 60%;
  margin: auto;

  ${({ isVideoCarousel }) =>
    isVideoCarousel ? "height: auto; width: 80%;" : ""};

  position: relative;

  @media only screen and (min-width: ${IMAC_BREAKPOINT}px) {
    max-height: 750px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 65vw;
    width: 100%;
  }
`;

const PlayButton = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 35px;
    height: 35px;
  }

  &:hover {
    opacity: 0.8;
  }

  transition: opacity 0.2s;
`;
const StyledIframe = styled.iframe<{
  $display?: boolean;
  isVideoCarousel?: boolean;
}>`
  aspect-ratio: 16/9;
  ${({ $display }) => ($display ? "display: block;" : "display:none;")};
  ${({ isVideoCarousel: isVideoCarousel }) =>
    isVideoCarousel && "height: 500px;"};

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ isVideoCarousel: isVideoCarousel }) =>
      isVideoCarousel && "height: 300px;"};
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: inherit;
  }
`;

const StyledCarousel = styled(Carousel)`
  width: 90%;
  margin: auto;
  .slick-dots {
    margin: 0px;
    margin-top: 20px;
    color: transparent;
    width: 100%;
    bottom: -50px;
    li {
      margin-right: 20px;
    }
    z-index: auto;
  }

  .ant-carousel .slick-dots li button {
    opacity: 1;
  }
  .slick-dots li.slick-active button:before {
    font-size: 20px;
    border: 2px solid ${colors.lightBlue};
    border-radius: 12px;
    background-color: ${colors.lightBlue};
  }

  .slick-dots li button:before {
    font-size: 20px;
    color: transparent;
    border: 2px solid ${colors.lightBlue};
    border-radius: 12px;
    opacity: 1;
  }
`;

const Arrow = styled.img<{
  right?: boolean;
}>`
  filter: brightness(0.5);
  display: block;
  z-index: 2;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${({ right }) => (right ? "right: 0px;" : "left: 0px;")};
`;

export default function VideoBlock(props: Props) {
  const {
    title,
    description,
    videoUrl,
    thumbnail,
    isVideoCarousel,
    video,
    hasThumbnail,
    customMargin,
    customMobileMargin,
  } = props;

  const [thumbnailClicked, toggleThumbnail] = useState(false);

  useEffect(() => {
    var iframe = document.getElementById("vimeoPlayer");
    if (iframe && thumbnailClicked) {
      const player = new Vimeo(iframe);
      player.play();
    }
  }, [thumbnailClicked]);

  const carouselSettings: CarouselProps = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    draggable: true,
    adaptiveHeight: true,
    lazyLoad: "ondemand",
  };
  const carouselSlider = useRef<CarouselRef>(null);

  return (
    <Container
      mobileMargin="50px auto 25px auto"
      position="relative"
      margin="100px auto 0px auto"
      maxWidth="1920px"
    >
      <Helmet>{videoUrl && <link rel="preconnect" href={videoUrl} />}</Helmet>
      <Title>{title}</Title>
      <Description>{description}</Description>

      <ImageWrapper
        isVideoCarousel={isVideoCarousel}
        hasThumbnail={hasThumbnail}
      >
        {!thumbnailClicked && thumbnail && (
          <Image data={thumbnail} objectFit="cover" width="100%" />
        )}
        {!thumbnailClicked && thumbnail !== undefined && thumbnail && (
          <PlayButton
            src={icons.playButton}
            alt="Play Button"
            onClick={() => (thumbnailClicked ? null : toggleThumbnail(true))}
            width={35}
            height={35}
          />
        )}

        {isVideoCarousel ? (
          <Container
            width="100%"
            tabletWidth="90%"
            margin={customMargin ? customMargin : "auto"}
            mobileMargin={customMobileMargin ? customMobileMargin : "auto"}
            position="relative"
            maxWidth={`${MAX_WIDTH}px`}
          >
            <Container position="absolute" width="100%" height="100%" top="0px">
              <Arrow
                src={icons.leftArrow}
                alt="Left Arrow"
                onClick={() => carouselSlider?.current?.prev()}
              />
              <Arrow
                right
                src={icons.rightArrow}
                alt="Right Arrow"
                onClick={() => carouselSlider?.current?.next()}
              />
            </Container>
            <StyledCarousel {...carouselSettings} ref={carouselSlider}>
              <StyledIframe
                $display={thumbnail ? thumbnailClicked : true}
                id="vimeoPlayer"
                src={video?.vimeoUrl ?? ""}
                title="test"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                width="100%"
                height="auto"
                allowFullScreen
                key={video?._key ?? ""}
                isVideoCarousel
              />
            </StyledCarousel>
          </Container>
        ) : !thumbnail || thumbnailClicked ? (
          <StyledIframe
            $display={true}
            id="vimeoPlayer"
            src={videoUrl ?? ""}
            title="test"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            width="100%"
            height="inherit"
            allowFullScreen
          />
        ) : null}
      </ImageWrapper>
    </Container>
  );
}
