import { Container } from "@util/standard";
import React from "react";
import styled from "styled-components";
import { Maybe, SanityAboutTeamCatogory } from "@graphql-types";
import Image from "@components/shared/sub/image";
interface Props {
  category: Maybe<SanityAboutTeamCatogory>;
}

const ImageWrapper = styled.div`
  height: 250px;
  width: 250px;
  margin: 20px auto;
`;

const StyledTextDiv = styled.div`
  text-align: center;
  width: 90%;
  margin: auto;
`;

const StyledP = styled.p`
  margin-bottom: 30px;
`;

const TeamBriefContentBlock = (props: Props) => {
  const { category } = props;

  return (
    <Container backgroundColor="white" width="320px" borderRadius={7}>
      {category?.image?.asset && (
        <ImageWrapper>
          {/* <Img
            fixed={category.image.asset.fixed}
            alt={category.image.altText}
          /> */}
          {category.image && <Image data={category.image} />}
        </ImageWrapper>
      )}
      <StyledTextDiv>
        <h3>{category?.title}</h3>
        <StyledP>{category?.description}</StyledP>
      </StyledTextDiv>
    </Container>
  );
};

export default TeamBriefContentBlock;
