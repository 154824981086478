import React from "react";
import { Maybe, SanityAboutTeamBrief } from "@graphql-types";
import { Container, MenuButtonGatsbyLink } from "@util/standard";
import BlocksContent from "@components/shared/sub/blockContent";
import styled from "styled-components";
import TeamBriefContentBlock from "@components/shared/sub/teamBriefContentBlock";
import { TABLET_BREAKPOINT } from "@util/constants";

interface Props {
  teamBrief: Maybe<SanityAboutTeamBrief> | undefined;
}

const StyledBlocksContent = styled(Container)`
  width: 50%;
  margin: auto;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 40px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 90%;
  }
`;

const TeamBrief = (props: Props) => {
  const { teamBrief: data } = props;

  if (!data) {
    return (
      <Container backgroundColor="babyBlue" width="100%" textAlign="center" />
    );
  }

  return (
    <Container backgroundColor="babyBlue" width="100%" textAlign="center">
      {data.celoTeamHeader && (
        <StyledBlocksContent>
          <BlocksContent blocks={data.celoTeamHeader._rawContent} />
        </StyledBlocksContent>
      )}
      {data?.teamCatogories && (
        <Container
          display="flex"
          flexWrap="wrap"
          flexDirection="row"
          gridGap="30px"
          justifyContent="center"
          maxWidth="1440px"
          margin="auto"
        >
          {data.teamCatogories.map(category => (
            <TeamBriefContentBlock
              key={category?._key ?? "content_key"}
              category={category}
            />
          ))}
        </Container>
      )}
      <Container margin="40px auto" display="inline-block" width="100%">
        {data && data.button && (
          <MenuButtonGatsbyLink
            href={data.button.url as string}
            width="fit-content"
            padding="10px 25px 10px 25px"
            color="secondary"
          >
            {data.button.linktext}
          </MenuButtonGatsbyLink>
        )}
      </Container>
    </Container>
  );
};

export default TeamBrief;
